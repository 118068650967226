var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-shop-orders" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ]),
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.refresh,
          attrs: { title: "Refresh" },
          on: { click: _vm.refreshList }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "list-containter" },
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [_c("h2", [_vm._v("Shopify Order Admin")])]),
            _c(
              "b-card-body",
              [_c("admin-shop-order-list", { ref: "adminOrderList" })],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }