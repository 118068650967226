<template>
  <div class="admin-shop-orders">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh" @click="refreshList"></i>
        <!--        <i class="mx-1 icon-button" :class="$config.icons.user.add" v-b-tooltip title="Add a User" @click="createUser"></i>-->
        <!--        <router-link to="/admin/users/bulkadd"><i class="mx-1 icon-button" :class="$config.icons.user.bulkAdd" v-b-tooltip title="Upload Users"></i></router-link>-->
        <!--        <i class="fa fa-user-times mx-1 icon-button" v-b-tooltip title="Delete Selected Users" @click="deleteSelected"></i>-->
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Shopify Order Admin</h2></b-card-header>
        <b-card-body>
          <admin-shop-order-list ref="adminOrderList"></admin-shop-order-list>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import AdminShopOrderList from '../../components/admin/AdminShopOrderList'

export default {
  name: 'admin-shop-orders',
  components: {
    AdminShopOrderList
  },
  data: function () {
    return {
      selectedUsers: []
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    selectChange: function (newSelection) {
      this.selectedUsers = newSelection
    },
    refreshList: function () {
      this.$refs.adminOrderList.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-shop-orders {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
